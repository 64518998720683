<template>
  <div>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #10623f;">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="@/assets/logo/logo-putih.png" alt="Your Logo" style="height: 40px; width: auto;">
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="#/pages/index" style="color: #fff;">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" style="color: #fff;">Gallery</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#/pages/basa-eta" style="color: #fff;">Basa Eta</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" style="color: #fff;">Contact</a>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a class="nav-link" @click="logout()" href="#" style="color: #fff;">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

   <!-- Hero Section -->
   <div class="c-app-container">
    <div class="c-app-overlay"></div>
      <div class="hero-section d-flex align-items-center" :style="{backgroundImage: 'url(' + require('@/assets/logo/bg.jpeg') + ')', backgroundSize: 'cover', color: '#10623f', height: '800px'}">
        <div class="text-overlay">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8 text-center">
              <h1 class="display-4" style="color: #ffffff; font-weight: bold;">SALAM ALUMNI 88 !</h1>
              <p class="lead" style="color: #ffffff; font-weight: bold;"> Di sini, kita membangun kembali ikatan, berbagi pengalaman, dan menjelajahi peluang bersama</p>
              <router-link to="/pages/login-user" class="btn btn-primary btn-lg m-3" style="background-color: #10623f; border-color: #10623f; color: #fff;">Login</router-link>
              <router-link to="/pages/survey-alumni" class="btn btn-primary btn-lg" style="background-color: #10623f; border-color: #10623f; color: #fff;">Register</router-link>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <!-- Visi dan Misi Section -->
    <section id="visi" class="py-5 d-flex align-items-center" style="background-color: #621033; color: #10623f; min-height: 800px;">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <!-- Visi Card -->
            <div class="card mb-4 pt-5 pb-5 card-transparent" style="border-color: #10623f;">
              <div class="card-body">
                <h2 class="card-title display-4 text-center tebel">Visi</h2>
                <p class="card-text lead">
                  Menjadikan IA-588 sebagai wadah bagi alumni SMA Negeri 5 Bandung 
                  Angkatan 1988 yang aktif dan berkontribusi positif bagi Alumni, 
                  Almamater dan Masyarakat.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Misi Card -->
                <video
                id="myVideo"
                class="video-fluid"
                style="width: 100%; height: 295px;"
                autoplay
                muted
                loop
                playsinline
                controls
              >
                <source :src="videoSatu" type="video/mp4">
                Your browser does not support the video tag.
              </video>
          </div>
        </div>
      </div>
    </section>

    <section id="misi" class="py-5 d-flex align-items-center" :style="{backgroundImage: 'url(' + require('@/assets/sabaskom/sabaskom-5.jpeg') + ')', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', color: '#10623f', height: '800px'}">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <video
                id="myVideo"
                class="video-fluid"
                style="width: 100%; height: 500px;"
                autoplay
                muted
                loop
                playsinline
                controls
              >
                <source :src="videoDua" type="video/mp4">
                Your browser does not support the video tag.
              </video>
          </div>
          <div class="col-md-6">
            <!-- Misi Card -->
            <div class="card mb-4 pt-5 pb-5 card-transparent" style="border-color: #10623f;">
              <div class="card-body">
                <h2 class="card-title display-4 text-center tebel">Misi</h2>
                <p class="card-text lead">
                  <ol>
									<li>Membangun dan memperkuat wadah silaturahmi dan komunikasi alumni SMA Negeri 5 Bandung Angkatan 1988.</li>
									<li>Membina persatuan dan kesatuan alumni.</li>
									<li>Meningkatkan dan mengembangkan potensi, kompetensi alumni serta menciptakan jejaring di antara alumni.</li>
									<li>Menjadikan mitra dengan pihak SMA Negeri 5 Bandung dalam upaya mendukung terselenggaranya pendidikan yang berkualitas.</li>
									<li>Menumbuh kembangkan kepedulian sosial dan peran serta dalam pemberdayaan masyarakat.</li>
								</ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Gallery Section -->
    <section id="gallery" class="py-5 d-flex align-items-center" style="background-color: #621033; color: #10623f; min-height: 800px;">
      <div class="container">
        <div class="card mb-4 card-transparent pt-5 pb-5" style="border-color: #10623f;">
          <div class="card-body">
            <h2 class="card-title display-4 text-center pb-5 tebel">Gallery</h2>
            <div class="row">
              <!-- Loop through the gallery array and display each image -->
              <div v-for="(item, index) in gallery" :key="index" class="col-md-4 mb-4">
                <img :src="item.image" class="img-fluid" :alt="'Image ' + (index + 1)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- berita Section -->
      <section id="news" class="py-5" :style="{backgroundImage: 'url(' + require('@/assets/sabaskom/sabaskom-7.jpeg') + ')', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', color: '#10623f', height: '800px'}">
        <div class="container">
          <div class="card mb-4 card-transparent p-5" style="border-color: #10623f;">
            <div class="card-body">
            <h2 class="display-4 text-center pb-5 tebel">News</h2>
            <div class="row">
              <!-- Loop through the news array and display each news item -->
              <div v-for="(item, index) in news" :key="index" class="col-md-4 mb-4">
                <div class="card" style="border-color: #10623f;">
                  <img :src="item.image" class="card-img-top" :alt="item.name" style="width: 100%; height: 300px; object-fit: contain;">
                  <div class="card-body">
                    <h5 class="card-title">{{ item.name }}</h5>
                    <p class="card-text">{{ item.content }}</p>
                    <p class="card-text"><small class="text-muted">{{ item.created_at }}</small></p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    <!-- /berita Section -->

    <!-- berita Section -->
    <section id="pengumuman" class="py-5" style="background-color: #621033; color: #10623f; min-height: 800px;">
      <div class="container">
        <div class="card mb-4 card-transparent p-5" style="border-color: #10623f;">
          <div class="card-body">
          <h2 class="display-4 text-center pb-5 tebel">Pengumuman</h2>
          <div class="row">
            <!-- Loop through the news array and display each news item -->
            <div v-for="(item, index) in pengumuman" :key="index" class="col-md-4 mb-4">
              <div class="card" style="border-color: #10623f;">
                <img :src="item.image" class="card-img-top" :alt="item.name" style="width: 100%; height: 300px; object-fit: contain;">
                <div class="card-body">
                  <h5 class="card-title">{{ item.name }}</h5>
                  <p class="card-text">{{ item.content }}</p>
                  <p class="card-text"><small class="text-muted">{{ item.created_at }}</small></p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </section>
    <!-- /berita Section -->

    <!-- Pengumuman Section -->
    <section id="pengumuman" class="py-5 d-flex align-items-center" :style="{backgroundImage: 'url(' + require('@/assets/sabaskom/sabaskom-10.jpeg') + ')', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', color: '#10623f', height: '800px'}">
      <div class="container">
        <div class="row">
          <!-- First Column with Lorem Ipsum Text -->
          <div class="card mb-4 card-transparent" style="border-color: #10623f;">
          <div class="card-body">
            <h2 class="card-title display-4 text-center pb-3 tebel">Kata Sambutan</h2>
              <video
                id="myVideo"
                class="video-fluid m-4"
                style="width: 100%; height: 300px;"
                autoplay
                muted
                loop
                playsinline
                controls
              >
                <source :src="videoTiga" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <p class="lead tebel text-center">
                Selamat datang, angkatan alumni '88! Kehadiran kalian di halaman ini membawa kilatan
                kenangan dan nostalgia. Setiap langkah kita membentuk jejak kisah masa lalu yang tak
                terlupakan. Di sini, mari kita sambut setiap pencapaian dan pengalaman hidup yang
                membentuk kita menjadi apa yang kita raih saat ini. Website ini menjadi tempat virtual
                kita untuk terhubung, berbagi cerita, dan merencanakan masa depan bersama. 
                Teruslah berkunjung, berinteraksi, dan membangun komunitas yang semakin kuat. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Pengurus Section -->
    <section id="pengurus" class="py-5 d-flex align-items-center" style="background-color: #621033; color: #10623f; min-height: 800px;">
      <div class="container">
        <div class="card mb-4 card-transparent pt-5 pb-5" style="border-color: #10623f;">
          <div class="card-body">
            <h2 class="card-title display-4 text-center pb-5 tebel">Pengurus</h2>
            <div class="row">
              <!-- Person 1 -->
              <div class="col-md-4 mb-4">
                <div class="card" style="border-color: #10623f;">
                  <img src="@/assets/logo/person.jpg" class="card-img-top" alt="Person 1">
                  <div class="card-body">
                    <h5 class="card-title">John Doe</h5>
                    <p class="card-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </div>
              </div>

              <!-- Person 2 -->
              <div class="col-md-4 mb-4">
                <div class="card" style="border-color: #10623f;">
                  <img src="@/assets/logo/person.jpg" class="card-img-top" alt="Person 2">
                  <div class="card-body">
                    <h5 class="card-title">Jane Smith</h5>
                    <p class="card-text">
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>
              </div>

              <!-- Person 3 -->
              <div class="col-md-4 mb-4">
                <div class="card" style="border-color: #10623f;">
                  <img src="@/assets/logo/person.jpg" class="card-img-top" alt="Person 3">
                  <div class="card-body">
                    <h5 class="card-title">Alex Johnson</h5>
                    <p class="card-text">
                      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 

    <!-- Footer -->
    <footer class="text-light py-4" style="background-color:#10623f ; color: #fff;">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p>&copy; 2023 Your Brand. All rights reserved.</p>
          </div>
          <div class="col-md-6 text-right">
            <a href="#" class="text-light">Privacy Policy</a> | <a href="#" class="text-light">Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        videoSatu: require('@/assets/sabaskom/video-alumni-2.mp4'),
        videoDua: require('@/assets/sabaskom/video-alumni-3.mp4'),
        videoTiga: require('@/assets/sabaskom/video-alumni.mp4'),
        news: [],
        pengumuman: [],
        gallery: [],
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 6,
          page: 1,
          keyword: "",
        },
      };
    },
    methods: {
          logout() {
            this.$store.dispatch("auth/logout").then(() => {
              this.$toast.success("Logout Berhasil");
              this.$router.push({ name: 'Basa Eta' });
            });
          },
          getPengumuman() {
            this.$store
              .dispatch("pengumuman/getPengumumanLanding", this.params)
              .then((resp) => {
                this.pengumuman = resp.data.data
                console.log("pengumuman",this.pengumuman)
              })
              .catch((e) => {
                console.log(e)
              })
          },
          getNews() {
            this.$store
              .dispatch("news/getNewsLanding", this.params)
              .then((resp) => {
                this.news = resp.data.data
                console.log("news",this.news)
              })
              .catch((e) => {
                console.log(e)
              })
          },
          getGallery() {
            this.$store
              .dispatch("gallery/getGalleryLanding", this.params)
              .then((resp) => {
                this.gallery = resp.data.data
                console.log("gallery",this.news)
              })
              .catch((e) => {
                console.log(e)
              })
          },
      },
      computed:  {
        isLoggedIn() {
            // Check if a token exists in the local storage
            return localStorage.getItem('token_user') !== null;
          },
      },
      mounted() {
        this.getPengumuman(),
        this.getNews(),
        this.getGallery()
      },
  };
</script>

<style>
/* Add your custom styles here */

/* Navbar */
.navbar {
  background-color: #343a40;
}

/* Hero Section */
.hero-section {
  padding: 100px 0;
}

/* Visi dan Misi Section */
#visi-misi {
  border-top: 5px solid #10623f;
  border-bottom: 5px solid #10623f;
}

/* Pengumuman and Gallery Section */
#pengumuman,
#gallery {
  padding: 60px 0;
  min-height: 800px;
}

.feature-section h2 {
  color: #10623f;
}

/* Footer */
.footer {
  background-color: #10623f;
  color: #fff;
}

.c-app-container {
    position: relative;
  }

  .hero-section {
  position: relative;
  padding: 100px 0;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Place the text overlay above the background overlay */
}

.tebel {
  font-weight: bold;
}

.c-app-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity here */
  z-index: 1; /* Ensure the overlay is above the background image */
}

.card-transparent {
  background-color: rgba(255, 255, 255, 0.9); /* Adjust the opacity as needed */
}

@media (max-width: 768px) {
    /* Adjust the height for smaller screens */
    .hero-section,
    #visi,
    #misi,
    #gallery,
    #pengumuman,
    #pengurus {
      height: 600px; /* Adjust the height for smaller screens */
    }
  }

   @media (max-width: 768px) {
    /* Adjust the height for smaller screens */
    #pengurus {
      min-height: 1600px; /* Adjust the height for smaller screens */
    }
  }
</style>
